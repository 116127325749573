import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { RiChatSmile2Fill } from 'react-icons/ri';

import ContactForm from '../components/ContactForm';
import CustomAccordion from '../components/Accordian';

function Contact() {
	//wiggle animation
	const [ref, inView] = useInView({
		triggerOnce: true, // Only trigger once
		threshold: 0.5, // Trigger when 50% of the element is visible
	});

	const iconSpring = useSpring({
		to: [
			{ transform: 'rotate(10deg)' },
			{ transform: 'rotate(-10deg)' },
			{ transform: 'rotate(10deg)' },
			{ transform: 'rotate(0deg)' },
		],
		from: { transform: 'rotate(0deg)' },
		config: { duration: 300 }, // Adjust duration as needed
		immediate: !inView, // Don't animate if not in view
	});

	return (
		<div className='contact bg-dark px-4 py-32'>
			<div className='relative md:text-center md:flex justify-center md:ml-[-220px] px-4 py-10 mb-12 md:py-32 bg-dark'>
				<h2 className='text-light'>
					At the end of the day, it's how your website
				</h2>{' '}
				<div className='words-1 inline md:ml-5'>
					<h2>makes people feel.</h2>
					<h2>captures your audience.</h2>
					<h2>builds trust.</h2>
					<h2>delivers value.</h2>
					<h2>is optimized.</h2>
					<h2>drives business.</h2>
				</div>
			</div>

			<div className='container md:flex justify-between gap-20'>
				<div className='w-full md:w-1/2 mb-20 md:mb-0'>
					<div id='contact' className='flex items-center gap-4' ref={ref}>
						<h2 className='text-center md:text-left' data-aos='fade-right'>
							LET'S CHAT
						</h2>
						<animated.h2 style={iconSpring}>
							<RiChatSmile2Fill />
						</animated.h2>
					</div>
					<ContactForm />
				</div>
				<div className='faq w-full md:w-1/2'>
					<h2 className='text-center'>FAQ's</h2>
					<CustomAccordion />
				</div>
			</div>
		</div>
	);
}

export default Contact;
