//this is my portfolio page, moskacreative.com/portfolio

//components
import React from 'react';

//data
import projectData from '../data/projects.json';

//styling
import '../scss/pages/_portfolio.scss';

//imgs

function Portfolio() {
	return (
		<div className='portfolio bg-dark px-4 py-32'>
			<div className='portfolio-header text-left mx-auto'>
				<h2>Projects</h2>
			</div>
			<div className='portfolio-list'>
				{projectData.projects.map((project) => (
					<a
						href={`portfolio/${project.url}`}
						key={project.id}
						className='project'
						data-aos='fade-up'
					>
						<img
							src={process.env.PUBLIC_URL + '/imgs/' + project.image}
							alt={`website design and development for ${project.title}`}
						/>
						<p className='project-type'>{project.type}</p>
						<h3>{project.title}</h3>
						<div className='project-links'>
							<a
								className='project-details'
								href={`portfolio/${project.url}`}
								aria-label='View Project Button'
							>
								Project Details
							</a>
						</div>
					</a>
				))}
			</div>
			{/* projects ends */}
			<div className='portfolio-header mx-auto text-left'>
				<h2>Design Gallery</h2>
			</div>
			<div className='case-list'>
				{projectData.designGallery.map((item) => (
					<div key={item.id} className='case' data-aos='fade-up'>
						<img
							src={process.env.PUBLIC_URL + '/imgs/' + item.image}
							alt={`website design and development for ${item.title}`}
						/>
						<p className='case-type'>{item.type}</p>
						<h3>{item.title}</h3>
					</div>
				))}
			</div>
		</div>
	);
}

export default Portfolio;
