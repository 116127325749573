import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';

import '../scss/components/_socials.scss';

const Socials = ({ className, color }) => {
	return (
		<div className={`socials ${className} ${color}`}>
			{' '}
			<a
				href='https://www.linkedin.com/in/maya-chrzanowska-ab55191a2/'
				aria-label='Linkedin'
				target='_blank'
				rel='noreferrer'
			>
				<FaLinkedinIn size='3rem' />
			</a>
			<a
				href='https://www.instagram.com/mchrzanka/'
				aria-label='Instagram'
				target='_blank'
				rel='noreferrer'
			>
				<BsInstagram size='3rem' />
			</a>
		</div>
	);
};

export default Socials;
