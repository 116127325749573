import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import '../scss/components/_accordion.scss';

const AccordionItem = ({ header, ...rest }) => (
	<Item
		{...rest}
		header={<>{header}</>}
		buttonProps={{
			className: ({ isEnter }) =>
				isEnter ? 'itemBtnExpanded accordion-btn' : 'accordion-btn',
		}}
	/>
);

export default function CustomAccordion() {
	return (
		<Accordion className='accordion bg-dark mx-auto'>
			<AccordionItem header='What types of websites can you make?'>
				<p>
					I can do long landing pages, multi page websites, content management
					systems (great for things like blogs or if you want access to your
					content), and e-commerce.
				</p>
			</AccordionItem>
			<AccordionItem header='Could I DIY my own website?'>
				<p>
					You could, but you would be missing out on the expertise of a
					developer! Investing in a professional means having a website that is
					more than just colors and a logo - all of the intricate parts are
					taken care of, including the behind the scenes essentials (such as
					SEO, accessibility, performance, etc).
				</p>
				<p>
					Many DIY solutions aren't made for growth and scalability, which is
					crucial if you want your business to thrive. They also lack
					customization in terms of styling and design - there are countless
					websites that follow trends and have no unique spark of their own.{' '}
				</p>
				<p>
					Other benefits of a developer include having a live website faster,
					ongoing support (for when other parts of your business or life need
					your attention), and working with someone who knows all the little
					details of creating a successful online presence.
				</p>
			</AccordionItem>

			<AccordionItem header='If I already have a site, can you still do work on it?'>
				<p>
					The short answer is it depends, but generally yes. It depends on my
					familiarity with the framework or CMS. I can either redo/update
					certain sections of your website, or I can do a complete redesign and
					redevelopment to bring new life into your website.
				</p>
				<p>
					If you have any questions in regards to partial work, contact me and
					we can chat about it!
				</p>
			</AccordionItem>

			<AccordionItem header='What do I need to have prepared to start work on my website?'>
				<p>
					All you need is your vision! There are many details to building a
					website, some of them being a logo, content, images, features, pages,
					and more. These details will be refined during the discovery phase of
					the project, and I'll guide you to the best solutions for your brand.
				</p>
			</AccordionItem>

			<AccordionItem header='What will my website be built on?'>
				<p>
					This will depend on your needs. If you want to be able to have control
					over the content and manage it independently, I will set up a headless
					Content Management System (CMS). If you need prefer a hands-off
					website, I'll custom program one for you. I don't use site builders
					like wix or squarespace.
				</p>
			</AccordionItem>

			<AccordionItem header='How much does a website cost?'>
				<p>
					Investing in a website for your brand is crucial to maintaining a
					strong online presence. So many leads are lost due to a poor first
					impression, so it's important to have a powerfully designed website
					that excels both visually and experientially.
				</p>
				<p>
					For me to give you an exact quote, I need to know some details about
					your project. After reaching out, I'll send you a client questionnaire
					which gives me all of the information I need to give provide you with
					a quote.
				</p>
			</AccordionItem>
		</Accordion>
	);
}
