import React from 'react';
import BloomPackageForm from '../components/BloomPackageForm';
import BloomDiscoveryForm from '../components/BloomDiscoveryCall';

//styling
import '../scss/pages/_startProject.scss';

// pricing:
// 5 pageXOffset, wordpress
// $3600 normal, on sale for $2300 rn

export default function StartProject() {
	return (
		<div className='start-project-content'>
			<div className='notice'>
				<h2>Packages coming soon!</h2>
				{/* <h2>Website packages that get your project off the ground faster.</h2>
				<p>
					Avoid the back and forth of starting a new project by easily booking a
					package below, with 100% transparency on pricing and expectations. If
					you'd like to have a chat first to talk about your project, book a
					free 30 minute discovery call.
				</p> */}

				{/* todo: add loader */}
				{/* 
				<BloomPackageForm />

				<BloomDiscoveryForm /> */}
			</div>
		</div>
	);
}
