import React from 'react';
import { useParams, Link } from 'react-router-dom'; //returns an object containing key value pairs from the current URL parameters. Each key corresponds to a parameter defined in your route (in this case, /portfolio/:projectName)
import { useSpring, animated } from 'react-spring';

import { HiArrowLongRight } from 'react-icons/hi2';

import '../scss/components/_portfolioView.scss';
import projectData from '../data/projects.json';

function PortfolioView() {
	const scrollToTop = () => {
		// Scroll to the top of the page
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional smooth scrolling animation
		});
	};

	const fadeIn = useSpring({
		to: { opacity: 1 },
		from: { opacity: 0 },
		config: { duration: 400 },
	});

	const { projectName } = useParams(); //grabs the id from the url. The const name has to match the wording set in the router. {} syntax is known as "destructuring assignment". Equivalent to:
	//const params = useParams();
	//const projectName = params.projectName;

	const project = projectData.projects.find((item) => item.url === projectName);
	const fonts = project.detailSpecs.fonts;
	const colors = project.detailSpecs.colors;

	return (
		<div className='portfolio-view bg-dark pt-32'>
			{project ? (
				<animated.div className='container' style={fadeIn}>
					<div className='portfolio-view_left'>
						<div className='specs'>
							<p className='project-type'>{project.type}</p>
							<h1 className='project-title'>{project.title}</h1>
							<div className='palette'>
								{colors.map((color, index) => (
									<span key={index} style={{ backgroundColor: color }}></span>
								))}
							</div>
							<div className='fonts'>
								<p
									className='font-title'
									style={{
										fontFamily: fonts.headerFontFamily,
									}}
								>
									{fonts.headerFont}
								</p>
								<p
									className='font-body'
									style={{
										fontFamily: fonts.bodyFontFamily,
									}}
								>
									{fonts.bodyFont}
								</p>
							</div>
						</div>
						<div className='project-desc'>
							<p>{project.description}</p>

							{project.live_url ? (
								<div className='project-links'>
									<a
										href={project.live_url}
										target='_blank'
										rel='noopener noreferrer'
										className='project-details'
									>
										Live Site <HiArrowLongRight size='3rem' />
									</a>
								</div>
							) : (
								<div></div>
							)}
						</div>
					</div>
					<div className='portfolio-view_right'>
						<img
							src={process.env.PUBLIC_URL + '/imgs/' + project.image}
							alt={`website design and development for ${project.title}`}
						/>
					</div>
					<div className='portfolio-view_bottom'>
						<div className='btns'>
							<Link to='/portfolio'>
								<button className='btn btn--secondary' onClick={scrollToTop}>
									Back to Portfolio
								</button>
							</Link>
						</div>
					</div>
				</animated.div>
			) : (
				<div>
					<p>Project not found</p>
				</div>
			)}
		</div>
	);
}

export default PortfolioView;
