import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import PortfolioView from './pages/PortfolioView';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import StartProject from './pages/StartProject';
import Contact from './pages/Contact';

function App() {
	useEffect(() => {
		AOS.init({
			duration: 1000, // controls the duration of the animation
			easing: 'ease-out', // easing function for animation
			once: true, // whether animation should happen only once - while scrolling down
		});
	}, []);

	return (
		<Router>
			<div className='App'>
				<>
					<Navbar />
					<Routes>
						<Route path='/' element={<Home />}></Route>
						<Route path='/portfolio' element={<Portfolio />}></Route>
						<Route
							path='/portfolio/:projectName'
							element={<PortfolioView />}
						></Route>
						<Route path='/contact' element={<Contact />}></Route>
						<Route path='/startproject' element={<StartProject />}></Route>
					</Routes>
					<Footer />
				</>
			</div>
		</Router>
	);
}

export default App;
