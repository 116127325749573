import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

//components

//data
import projectData from '../data/projects.json';

//styling
import '../scss/pages/_home.scss';
import '../scss/components/_button.scss';

function Home() {
	const scrollToTop = () => {
		// Scroll to the top of the page
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional smooth scrolling animation
		});
	};

	return (
		<div className='home-content bg-light'>
			<div
				className='pt-32 md:pt-[120px] h-screen bg-contain'
				style={{
					backgroundImage: 'url(/imgs/website-developer-banner.webp)',
				}}
			>
				<div className='container h-screen'>
					<div className='pt-8 md:pt-16 md:mx-auto'>
						<h1>MAYA CREATIVE.</h1>
						<p className='tagline text-center'>
							Websites inspired by the outdoors.
						</p>
					</div>
					<div className='max-w-xl w-full mx-auto mt-12 mb-8'>
						<img
							src={process.env.PUBLIC_URL + '/imgs/outdoor-web-designer.png'}
							alt='line drawing of a heart suggesting heart-led branding'
						/>
					</div>
					<div>
						<a
							href='/contact'
							className='py-4 px-4 flex items-center gap-2 mx-auto md:w-1/5 justify-center hover:bg-cta hover:text-dark transition rounded-2xl'
							onClick={scrollToTop}
						>
							Let's Get Started <Icon icon='iwwa:arrow-right' />
						</a>
					</div>
				</div>
			</div>

			{/* services */}
			<div className='w-full mx-auto flex justify-center px-4 py-10 md:py-32 bg-dark rounded-t-[20px] 2xl:rounded-t-[60px] mt-[-60px] relative text-light'>
				<div className='custom-glowing-circle h-32 w-32 absolute top-[300px] 2xl:top-[220px] left-[20px] 2xl:left-[540px] z-[0]'></div>
				<div className='custom-glowing-circle h-36 w-36 absolute right-[0px] 2xl:right-[340px] bottom-[140px] 2xl:bottom-[150px] z-[0]'></div>
				<div className='container'>
					<div className='text-center'>
						<p className='tagline text-light'>Specialized</p>
						<h2 className='text-light mt-[-15px]'>Services</h2>
						<p className='text-light max-w-6xl mx-auto mb-16'>
							Elevate your business' online presence, either through a brand new
							website or a redesign. My web design and development services
							specialize in websites inspired by the outdoors. You bring the
							vision, and I'll take care of the rest.
						</p>
					</div>

					<div className='flex flex-col md:flex-row gap-10 items-center text-center'>
						<div
							className='bg-transparent md:w-1/3 border-[1px] border-cta/60 border-solid rounded-3xl p-10'
							data-aos='zoom-in-up'
						>
							<div className='card-body items-center text-center'>
								<figure className='flex justify-center'>
									<Icon icon='mdi:seed-outline' height={40} />
								</figure>
								<h3 className='text-light'>Brand Identity</h3>
								<p className='text-light'>
									Expressing your brand's mission through details like color,
									fonts, logo, and overall energy.
								</p>
							</div>
						</div>
						<div
							className='bg-transparent md:w-1/3 border-[1px] border-cta/60 border-solid rounded-3xl p-10'
							data-aos='zoom-in-up'
							data-aos-offset='300'
						>
							<div className='card-body items-center text-center'>
								<figure className='flex justify-center'>
									<Icon icon='mdi:seedling-outline' height={40} />
								</figure>
								<h3 className='text-light'>Design</h3>
								<p className='text-light'>
									More than beautiful colors and pictures; design brings your
									brand to life with intuition, strategy, and purpose.{' '}
								</p>
							</div>
						</div>
						<div
							className='bg-transparent md:w-1/3 border-[1px] border-cta/60 border-solid rounded-3xl p-10'
							data-aos='zoom-in-up'
							data-aos-offset='400'
						>
							<div className='card-body items-center text-center'>
								<figure className='flex justify-center'>
									<Icon icon='ri:tree-line' height={40} />
								</figure>
								<h3 className='text-light'>Development</h3>
								<p className='text-light'>
									All the behind the scenes taken care of through a modern,
									custom website that scales and performs.
								</p>
							</div>
						</div>
					</div>
					<div className='flex justify-center mt-12'>
						<Link to='/contact' className='flex'>
							<button
								className='btn btn--secondary mx-auto'
								onClick={scrollToTop}
							>
								Start a Project
							</button>
						</Link>
					</div>
				</div>
			</div>

			{/* about */}
			<div className='w-full px-4 py-10 md:py-32 bg-extradark'>
				<div className='container md:flex items-center'>
					<div className='md:flex md:w-1/2'>
						<div className='hidden md:block mt-[160px] mr-[-80px] z-10'>
							<img
								src='/imgs/web-designer.jpg' // Path relative to the `public` folder
								alt='maya web designer and developer headshot'
								className='max-w-lg max-h-96 object-cover object-center custom-corner-border'
								data-aos='fade-right'
								data-aos-offset='400'
							/>
						</div>
						<div className='m-4 z-0 ml-[0px]'>
							<img
								src='/imgs/Maya.png' // Path relative to the `public` folder
								alt='maya web designer and developer headshot'
								className='max-w-lg max-h-auto object-cover object-center custom-corner-border'
								data-aos='fade-right'
							/>
						</div>
					</div>
					<div className='px-4 text-center md:w-1/2 md:text-left'>
						<div className='flex items-center gap-1 justify-center md:justify-start mb-4 mt-8'>
							<Icon
								icon='mdi:hand-wave-outline'
								height={30}
								className='text-light'
							/>
							<h2 className='text-light'>Meet Maya</h2>
						</div>
						<p className='mb-4 text-light'>
							Hello! I'm Maya, a website designer located in Edmonton, AB. I'm
							also a gamer, cat mom, and outdoor enthusiast. I thrive both
							infront of the computer and exploring the outdoors, so I created
							Maya Creative Co to bring these two passions together.
						</p>
						<p className='text-light'>
							My web design specialty is creating outdoor themed websites for
							brands that love nature as much as I do. If this resonates with
							you, you can leave it to me to make your website remarkable.
						</p>
					</div>
				</div>
			</div>

			{/* portfolio */}
			<div className='bg-dark'>
				<div className='highlights mx-auto px-4 py-16 md:py-32'>
					<h2 className='text-center md:text-left'>Highlighted Projects</h2>
					<div className='highlights-list' data-aos='fade-up'>
						{projectData.highlights.map((project) => (
							<a
								href={`portfolio/${project.url}`}
								key={project.id}
								className='project'
							>
								<div className='vertical-text'>{project.summary}</div>
								<div className='img-container'>
									<img
										src={
											process.env.PUBLIC_URL +
											'/imgs/' +
											project.highlight_image
										}
										alt={`website design and development for ${project.title}`}
									/>
								</div>
								<div className='title'>
									<h3>{project.title}</h3>
								</div>
							</a>
						))}
					</div>
					<Link to='/portfolio' className='flex'>
						<button
							className='btn btn--secondary mx-auto'
							onClick={scrollToTop}
						>
							View More
						</button>
					</Link>
				</div>
			</div>

			{/* cta */}
			<div className='w-full bg-extradark relative text-light px-4 py-10 md:py-32'>
				<div className='flex flex-col gap-6 md:w-1/2 mx-auto px-4 py-10'>
					<h2 className='text-center text-light'>
						Are you beginning to think that
					</h2>
					<div className='flex items-center gap-2 justify-center'>
						<Icon icon='ph:smiley-sad-thin' height={30} className='w-auto' />
						<p className='w-2/3 text-light'>
							Your website is outdated and doesn't look modern.
						</p>
					</div>
					<div className='flex items-center gap-2 justify-center'>
						<Icon icon='ph:smiley-sad-thin' height={30} className='w-auto' />
						<p className='w-2/3 text-light'>
							Your brand's message and identity has evolved but your website
							hasn't.
						</p>
					</div>
					<div className='flex items-center gap-2 justify-center'>
						<Icon icon='ph:smiley-sad-thin' height={30} className='w-auto' />
						<p className='w-2/3 text-light'>
							Your website isn't converting clicks into loyal customers - if
							it's even getting clicks.
						</p>
					</div>
					<div className='flex items-center gap-2 justify-center'>
						<Icon icon='ph:smiley-sad-thin' height={30} className='w-auto' />
						<p className='w-2/3 text-light'>You don't have a website at all.</p>
					</div>
				</div>
				<div className='text-center pt-8 pb-12 md:pt-12 md:pb-20'>
					<Icon
						icon='system-uicons:face-happy'
						height={70}
						className='mx-auto'
					/>
					<p className='pt-2 max-w-2xl mx-auto text-light'>
						Don't do your brand a disservice by having a mediocre website. Your
						website deserves to reflect all of the sweat and love that went into
						building your business.{' '}
					</p>
					<p className='text-light pt-4'>
						I'd love to be your Edmonton based web designer and developer.
					</p>
					<Link to='/contact' className='flex'>
						<button
							className='btn btn--secondary mx-auto'
							onClick={scrollToTop}
						>
							Let's Chat
						</button>
					</Link>
				</div>

				{/* <Icon
					icon='system-uicons:face-happy'
					height={200}
					className='absolute -right-24 bottom-[40%] z-[0] custom-icon overflow-clip md:hidden'
				/>
				<Icon
					icon='system-uicons:face-happy'
					height={300}
					className='absolute right-[15%] bottom-[20%] z-[0] custom-icon hidden md:block'
				/> */}
			</div>
		</div>
	);
}

export default Home;
