import React from 'react';
import { Link } from 'react-router-dom';

import '../scss/components/_footer.scss';
import Logo from './Logo';
import Socials from './Socials';

function Footer() {
	const year = new Date();
	const scrollToTop = () => {
		// Scroll to the top of the page
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional smooth scrolling animation
		});
	};

	return (
		<footer className='bg-dark'>
			<div className='footer-container px-4 py-12'>
				<div className='footer_top'>
					<div className='top_one'>
						<div className='logo'>
							<a href='/'>mc.</a>
						</div>
					</div>
					<div className='top_three'>
						<ul>
							<li className='nav-item'>
								<Link to='/' className='nav-links' onClick={scrollToTop}>
									Home
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									to='/portfolio'
									className='nav-links '
									onClick={scrollToTop}
								>
									Portfolio
								</Link>
							</li>
							<li className='nav-item'>
								<Link to='/contact' className='nav-links' onClick={scrollToTop}>
									Contact
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className='hl2'></div>
				<div className='footer_bottom'>
					<div className='bottom_one'>
						<p>
							Maya Creative Co, located on Treaty 6 territory, holds deep
							respect and honour for the traditional lands of First Nations,
							Métis, and Inuit people on which we operate.
						</p>
					</div>
					<div className='bottom_two'>
						<p>© {year.getFullYear()} Maya Creative Co.</p>
					</div>
					<div className='bottom_three'>
						<Socials className={'hor'} />
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
