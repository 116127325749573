import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Socials from './Socials';

//styles
import '../scss/components/_navbar.scss';

function Navbar(props) {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	return (
		<>
			<nav className='navbar bg-transparent absolute w-full' role='navigation'>
				<div className='navbar-container flex justify-between items-center mx-auto pt-2 w-full'>
					<div className='logo'>
						<a href='/'>mc.</a>
					</div>
					<div>
						<ul className={click ? 'nav-menu active' : 'nav-menu'}>
							<li className='nav-item'>
								<Link to='/' className='nav-links' onClick={closeMobileMenu}>
									Home
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									to='/portfolio'
									className='nav-links'
									onClick={closeMobileMenu}
								>
									Portfolio
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									to='/contact'
									className='nav-links'
									onClick={closeMobileMenu}
								>
									Contact
								</Link>
							</li>
							{/* <li className='nav-item start-project'>
								<Link
									to='/startproject'
									className='nav-links'
									onClick={closeMobileMenu}
								>
									Start a Project
								</Link>
							</li> */}
							<li className='nav-item socials'>
								<Socials className={'hor'} color={'white'} />
							</li>
						</ul>
					</div>
					<div
						onClick={handleClick}
						aria-label='Main Menu'
						className={click ? 'hamburger is-active' : 'hamburger'}
					>
						<span className='line'></span>
						<span className='line'></span>
						<span className='line'></span>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Navbar;
